import {Button} from "antd";
import {Container} from "react-bootstrap";
import LanguageContext from "../../store/LanguageContext";
import {useContext} from "react";
import {getLabel} from "../../localization/main";
import {Link} from "react-router-dom";

const CustomHeader = (props) => {
    const {dir, language, changeLanguage} = useContext(LanguageContext);

    return(
    <Container fluid="md" className="pt-3 pb-3 border-bottom mb-4 mt-2" dir={dir}>
        <div className="w-100 d-flex justify-content-between align-items-center pt-2 pb-2" dir={dir}>
            <div dir={dir}>
                <Link to={`/${language}`} className="main-color fw-bold bg-black border-0 font-size-15 pe-3 ps-2 text-decoration-none">{getLabel("home")}</Link>
                <Link to={`/${language}/about-us`} className="main-color fw-bold bg-black border-0 font-size-16 pe-3 text-decoration-none">{getLabel("about_us")}</Link>
                <Link to={`/${language}/support`} className="main-color fw-bold bg-black border-0 font-size-16 pe-3 text-decoration-none">{getLabel("support")}</Link>
                <Link to={`/${language}/blog-articles`} className="main-color fw-bold bg-black border-0 font-size-16 pe-3 text-decoration-none">{getLabel("blogs")}</Link>

            </div>

            <Button onClick={changeLanguage} className="main-color fw-bold bg-black border-0 font-size-16">{language === "en"?"العربية":"English"}</Button>
        </div>
    </Container>
)
}


export default CustomHeader;
