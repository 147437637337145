import {useEffect, useRef, useState} from "react";
import Card from 'react-bootstrap/Card';
import {isValidValue} from "../helper_functions";
import useWindowSize from "../useWindowSize";
import {Carousel} from "react-bootstrap";
import {g_language} from "../../localization/main";
import {Link} from "react-router-dom";


const BlogCard = props => {
    const language = props.language || g_language;
    const data = props.data || {};
    const images = data.images || [];
    const img_ref = useRef();
    const [image_height, setImageHeight] = useState(200);
    const inner_width = useWindowSize().inner_width || 1000;

    useEffect(() => {
        if (isValidValue(img_ref)) {
            setImageHeight(img_ref.current.offsetWidth)
        }
    }, [inner_width, img_ref, ((img_ref || {}).current || {}).offsetWidth]);

    const viewBlogDetails = () => {
        props.history.push(`/${language}/blog-articles/${data[`slug_${language}`]}`);
    }

    return (

        <div className="w-100 h-100 border-0" ref={img_ref} onClick={viewBlogDetails}>
            <Carousel fade interval={5*60*1000} controls>
                {images.map((image, index) => (
                    <Carousel.Item key={index}>
                        <img
                            loading="lazy"
                            variant="top"
                            src={image.display_photo}
                            className="w-100 border-radius-20"
                            height={image_height}
                            width={image_height}
                        />
                    </Carousel.Item>
                ))}
            </Carousel>

            <div className="p-2">
                <h2 className={`text-dots-2-line main-color fw-bold font-size-18 line-height-1-25 height-45`}>
                    <Link href={`/${language}/blog/${data[`slug_${language}`]}`} className="text-decoration-none main-color">
                        {data[`title_${language}`]}
                    </Link>
                </h2>
            </div>


        </div>
    );
}

export default BlogCard;
