import {useContext, useEffect, useState} from "react";
import {httpRequestWithoutAuth} from "../global/axios_instance";
import {ListBlogsURL} from "../global/urls";
import {getLabel} from "../localization/main";
import CustomHeader from "../global/components/CustomHeader";
import {Carousel, Col, Container, Row} from "react-bootstrap";
import Hide from "../global/components/Hide";
import {Loading} from "../global/components/Loading";
import LanguageContext from "../store/LanguageContext";
import {Helmet} from "react-helmet";

const BlogDetails = (props) => {

    const url_params = props.match.params || {};
    const slug = url_params.slug || '';

    const {language} = useContext(LanguageContext);

    const [data, setData] = useState({images: []});
    const [loading, setLoading] = useState(true);

    const images = data.images || [];

    useEffect(()=>{
        const fetchData = async () =>{
            setLoading(true);
            let response = await httpRequestWithoutAuth(`${ListBlogsURL}${slug}`, "GET");
            if (response.status){
                setData(response.data);
            }
            setLoading(false);
        }


        fetchData();
    },[])


    return(
        <>
            <Hide hide={loading}>
            <Helmet>
                <title>{data[`title_${language}`] || "Blog"}</title>
                <meta
                    property="og:title"
                    name="title"
                    content={data[`title_${language}`] || "Blog"}
                    key="title"
                />
                <meta property="og:description" name="description" content={data[`description_${language}`]} key="description" />
                <meta
                    name="keywords"
                    content={data[`description_${language}`]}
                />

                <meta property="og:image" content={data?.images[0]?.display_photo}/>

            </Helmet>
            </Hide>

            <CustomHeader {...props}/>

            {/*<img src={BackGroundImg} className="vw-100 vh-100 position-absolute z-index-m1"/>*/}
            <Container fluid="md" className="h-100 pt-4 pb-4 text-white">
                <Row className="justify-content-center">

                    <Hide hide={!loading}>
                        <Loading/>
                    </Hide>

                    <Hide hide={loading}>
                        <Col xs={12}>
                            <div className="mb-5">
                                <h1 className="main-color font-size-36">
                                    {data[`title_${language}`]}
                                </h1>
                            </div>
                        </Col>

                        <Col xs={12} md={5}>
                        <Carousel className="overflow-hidden w-100" fade>
                            {images.map((image, index) => (
                                <Carousel.Item key={index}>
                                    <img
                                        loading="lazy"
                                        src={image.display_photo}
                                        alt={image.title}
                                        className="w-100 h-100"
                                        style={{width: 300, height: 300}}
                                        height={300}
                                        width={300}
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                        </Col>



                        <Col xs={12}>
                            <div className="w-100 p-2">
                                <div className="mt-2 main-color" dangerouslySetInnerHTML={{__html: data[`description_${language}`]}}/>
                            </div>
                        </Col>

                    </Hide>

                </Row>
            </Container>
                    </>
    )
}


export default BlogDetails;
