import React, {useContext} from "react";
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import {ConfigProvider} from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  PRIMARY_COLOR,
} from "./global/variables";
import "./App.css";
import Home from "./pages/Home";
import RestPasswordPage from "./pages/auth/RestPassword";
import Support from "./pages/Support";
import home from "./pages/Home";
import LanguageContext, {LanguageContextProvider} from "./store/LanguageContext";
import AboutUs from "./pages/AboutUs";
import ProductDeepLinking from "./pages/ProductDeepLinking";
import BlogArticles from "./pages/BlogArticles";
import BlogDetails from "./pages/BlogDetails";

ConfigProvider.config({
  theme: {
    primaryColor: PRIMARY_COLOR,
    // errorColor:DANGER_COLOR_2,
  },
});


const App =()=> {
    return (
        <LanguageContextProvider>
            <AppContent/>
        </LanguageContextProvider>
    );
}

const AppContent =(props)=> {
    const {dir} = useContext(LanguageContext);

    return (
      <div dir={dir} className="bg-black vh-100 overflow-hidden">
          <div className="h-100 overflow-scroll pb-5">

      <ConfigProvider direction={dir}>
        <BrowserRouter>
            <Switch>
            <Route path="/p" exact component={ProductDeepLinking}/>
            <Route path="/rest-password" exact component={RestPasswordPage}/>
            <Route path="/support" exact component={Support}/>
            <Route path="/about-us" exact component={AboutUs}/>
            <Route path="/blog-articles/:slug" component={BlogDetails}/>
            <Route path="/blog-articles" exact component={BlogArticles}/>
            <Route path="/:language" component={LanguageRoute}/>
            <Route path="/" exact component={Home}/>
            <Route path="*" exact render={() => <Redirect to="/"/>}/>
          </Switch>
        </BrowserRouter>
      </ConfigProvider>


          </div>
      </div>
  );
}


export const LanguageRoute = props => {

    return (
        <Switch>
            <Route path="/:language/about-us" exact component={AboutUs}/>
            <Route path="/:language/support" exact component={Support}/>
            <Route path="/:language/blog-articles/:slug" component={BlogDetails}/>
            <Route path="/:language/blog-articles" exact component={BlogArticles}/>
            <Route path="/:language" exact component={home}/>
        </Switch>
    )
}

export default App;
