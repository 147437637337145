const en = {
 "gallerias": "Gallerias",
 "home_header_1": "Gift Your Loved Ones",
 "With": "with",
 "home_p_1": "A better way to send gifts from a wide collection of luxury items",
 "contact_us": "Contact Us",
 "about_us": "About Us",
 "about_us_p": "Elevate your Gifting Experience with our exclusive mobile app. Explore a world of opulence and sophistication, where luxury meets convenience. Discover and send exquisite gifts that redefine the art of giving. Download Gallerias now and experience the epitome of elegance at your fingertips.",
 "our_mission": "Our Mission",
 "our_mission_p": "At Gallerias, our mission is to redefine the art of gifting by providing a seamless and opulent gift-giving experience. We aspire to connect individuals with the world of luxury, enabling them to effortlessly express their sentiments through carefully curated, exquisite gifts. Our commitment is to make every moment extraordinary and every gift unforgettable, setting a new standard for the joy of giving.",
 "our_vision": "Our Vision",
 "our_vision_p": "At Our vision at Gallerias is to be the unparalleled leader in luxury gift-giving, a global symbol of refined taste and extravagant expression. We envision a future where every gift becomes an extraordinary journey, and each gesture of kindness is adorned with the finest. Through continuous innovation, meticulous curation, and unwavering dedication to our users, we aspire to set the standard for elegance and exclusivity in the world of gift-giving.",
 "our_values": "Our Values",
 "elegance": "Elegance",
 "elegance_p": "We believe in the beauty of sophistication and strive to offer gifts that epitomize elegance.",
 "exclusivity": "Exclusivity",
 "exclusivity_p": "We are committed to sourcing and presenting exclusive, one-of-a-kind gifts that set new standards for luxury.",
 "personalization": "Personalization",
 "personalization_p": "We understand the importance of personal touch and provide the tools to create tailored, unforgettable gifting experiences.",
 "quality": "Quality",
 "quality_p": "We prioritize quality, ensuring that every gift meets the highest standards of craftsmanship and excellence.",
 "trust": "Trust",
 "trust_p": "We build trust through transparency, security, and reliability in every aspect of our app and service.",
 "innovation": "Innovation",
 "innovation_p": "We continuously innovate, embracing the latest technology to enhance user experiences and stay ahead in the luxury gifting landscape.",
 "customer-centric": "Customer-Centric",
 "customer-centric_p": "We put our users first, striving to exceed their expectations and provide exceptional customer service.",
 "community": "Community",
 "community_p": "We foster a community of luxury enthusiasts who share our passion for celebrating life's moments with the finest gifts.",
 "home": "Home",
 "support": "Support",
 "blog_articles": "Blog Articles",
 "no_blog_found": "No Blog Found",
 "blog": "Blog",
 "blogs": "Blogs",
 "default_keywords": "Gallerias, Gift, Luxury, Gift Your Loved Ones",
}


export default en;
