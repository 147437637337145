import "../assets/css/home.css";
import Logo from "../assets/img/logo.png";
import MobileImg from "../assets/img/mobile_img.png";
import InstagramImg from "../assets/img/instagram_icon.png";
import LinkedinImg from "../assets/img/linkedin_icon.png";
import SnapImg from "../assets/img/snap_icon.png";
import {Col, Container, Row} from "react-bootstrap";
import {AppStoreButton, GooglePlayButton} from "react-mobile-app-button";
import useWindowSize from "../global/useWindowSize";
import Hide from "../global/components/Hide";
import {getLabel} from "../localization/main";
import CustomHeader from "../global/components/CustomHeader";
import {Helmet} from "react-helmet";

const Support = (props) => {
    const {inner_width} = useWindowSize();

    const iOSUrl = "https://apps.apple.com/kw/app/gallerias/id6444364183";
    const APKUrl = "https://play.google.com/store/apps/details?id=com.gallerias_mobile_app";
    return (
        <>
            <Helmet>
                <title>{getLabel("contact_us")}</title>
                <meta
                    property="og:title"
                    name="title"
                    content={getLabel("contact_us")}
                    key="title"
                />
                <meta property="og:description" name="description" content={getLabel("default_keywords")} key="description" />
                <meta
                    name="keywords"
                    content={getLabel("default_keywords")}
                />

                <meta property="og:image" content="https://www.galleriasapp.com/static/media/logo.19e18ebe79256369bff6.png"/>
            </Helmet>

            <CustomHeader {...props}/>

            {/*<img src={BackGroundImg} className="vw-100 vh-100 position-absolute z-index-m1"/>*/}
            <Container fluid="md" className="h-100 pt-4 pb-4">
                <Row>
                    <Col xs={12} md={6}>
                        <Row>
                            <Col xs={12}>
                                <img src={Logo} className="w-25 logo mb-5"/>
                            </Col>

                            <Col xs={12}>
                                <div className="mt-5 text-white">
                                    <h1 className="text-white font-size-42">
                                        {getLabel("contact_us")}
                                    </h1>

                                    <div className="mt-4">
                                        <a className="m-2">
                                            <img src={LinkedinImg} width={30} height={30}/>
                                        </a>

                                        <a className="m-2">
                                            <img src={InstagramImg} width={30} height={30}/>
                                        </a>

                                        <a className="m-2">
                                            <img src={SnapImg} width={30} height={30}/>
                                        </a>
                                    </div>


                                    <div className="mt-5 fw-bold">
                                        <label className="font-size-18">(+965) 6659 2225</label>
                                        <br/>
                                        <label className="font-size-18">Support@galleriasapp.com</label>
                                    </div>
                                </div>
                            </Col>


                        </Row>
                    </Col>


                    <Hide hide={inner_width < 768}>
                    <Col xs={12} md={6} className="text-center ">
                        <img src={MobileImg} className="h-100 mw-100"/>
                    </Col>
                    </Hide>

                </Row>


            </Container>
        </>
    )
}


export default Support;
