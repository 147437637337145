export const BaseBackEndAPIsURL = process.env.REACT_APP_BACKEND_API_URL;

export const UsersURL = `${BaseBackEndAPIsURL}users/`;
export const RestPasswordURL = `${UsersURL}rest-password`;




export const BlogsURL = `${BaseBackEndAPIsURL}blogs/`;
export const ListBlogsURL = `${BlogsURL}list-active/`;


